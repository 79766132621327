<template>
  <b-card
    class="schedule tender-info p-2"
  >
    <h2>Расписание рейсов тендера</h2>
    <div class="schedule-routes">
      <div
        v-if="!dateSelected && !dateWithFlights && !tenderAdd"
        id="schedule-not-selected"
        class="schedule-routes__not-found d-flex justify-content-center align-items-center p-10"
      >
        <h4>Выберите нужный день для просмотра расписания</h4>
      </div>
      <div
        v-if="!dateSelected && !dateWithFlights && tenderAdd"
        id="schedule-not-selected"
        class="schedule-routes__not-found d-flex justify-content-center align-items-center p-10"
      >
        <h4>Нет данных</h4>
      </div>
      <div
        v-if="dateSelected && !dateWithFlights"
        id="schedule-not-found"
        class="schedule-routes__not-found d-flex justify-content-center align-items-center p-10"
      >
        <div
          v-if="selectedScheduleDate"
          class="schedule-route"
        >
          <div class="schedule-route__header mb-3">
            <h3>{{ selectedScheduleDate.day }} {{ getMonth(selectedScheduleDate.month) }}</h3>
          </div>
          <div class="schedule-route__body justify-content-center align-items-center mb-5">
            <h4
              key="text-muted"
              class="text-muted"
            >
              Нет рейсов на этот день
            </h4>
          </div>
        </div>
      </div>
      <div
        v-if="dateSelected && dateWithFlights"
        id="schedule-founded"
        class="d-flex justify-content-center align-items-center p-10 w-100"
      >
        <div
          v-if="selectedScheduleDate"
          class="schedule-route"
        >
          <div class="schedule-route__header mb-3">
            <h3>{{ selectedScheduleDate.day }} {{ getMonth(selectedScheduleDate.month) }}</h3>
          </div>
          <div class="schedule-route__body">
            <transition-group name="fade">
              <div
                v-for="(selectedFlight, index) in selectedScheduleDate.selectedFlights"
                :key="index"
              >
                <div class="d-flex flex-start mb-2 mt-4">
                  <h4 class="text-muted">
                    Маршрут №{{ index }}
                  </h4>
                </div>
                <div v-if="selectedFlight.length">
                  <div
                    v-for="(selectedFlightItem, idx) in selectedFlight"
                    :key="idx"
                    class="schedule-route__body-item w-100 mt-2"
                  >
                    <b-row class="d-flex justify-content-between pl-3 pr-2">
                      <h5 :key="`routes-col-1-header-${idx}`">
                        <strong>Рейс-{{ selectedFlightItem.code }}-{{ idx + 1 }}</strong>
                      </h5>
                      <h5>
                        Стоимость
                      </h5>
                    </b-row>
                    <b-row
                      :key="`routes-container-${idx}`"
                      v-b-popover.hover.top="{title: 'Пункты назначения',
                                              content: selectedFlightItem.department.length > 2 ? `<ul class='d-flex flex-column flex-column-reverse'>${selectedFlightItem.department.map((item) => {return `<li class='mr-3'>${item}</li>`})}</ul>` : 'Нет промежуточных пунктов',
                                              html: true}"
                      class="d-flex justify-content-between align-items-center mt-2"
                    >
                      <b-col
                        sm="2"
                        class="department"
                      >
                        <span class="d-flex text-wrap department-text justify-content-start">
                          <span
                            :key="`first-route-${idx}`"
                            class="fa fa-map-marker fa-lg mr-2 text-danger"
                          /><h5
                            :key="`text-1-${idx}`"
                            class="text-muted text-wrap"
                          >
                            {{ selectedFlightItem.department[selectedFlightItem.department.length - 1] }}
                          </h5>
                        </span>
                      </b-col>
                      <b-col
                        md="4"
                        class="d-flex flex-column justify-content-center align-items-center"
                      >
                        <span
                          v-if="selectedFlightItem.department.length > 2"
                          class="middle-routes-label"
                        >ещё {{ labelNumWord(selectedFlightItem.department.length - 2, ['пункт', 'пункта', 'пунктов']) }}</span>
                        <div
                          :key="`mid-route-${idx}`"
                          class="schedule-route__body-item__line"
                        />
                      </b-col>
                      <b-col
                        md="3"
                        class="department"
                      >
                        <span class="d-flex text-wrap department-text justify-content-start">
                          <span
                            :key="`last-route-${idx}`"
                            class="fa fa-map-marker fa-lg mr-2 ml-2 text-danger"
                          /><h5
                            :key="`text-2-${idx}`"
                            class="text-muted"
                          >
                            {{ selectedFlightItem.department[0] }}
                          </h5>
                        </span>
                      </b-col>
                      <b-col
                        :key="`routes-col-2-${idx}`"
                        sm="2"
                        class="d-flex w-100 justify-content-end"
                      >
                        <span>
                          <h5
                            :key="`routes-col-2-price-${idx}`"
                            class="text-muted"
                          >
                            {{ numberRUFormat(selectedFlightItem.routePrice) }} ₽
                          </h5>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row class="ml-3 mb-1">
                      <span class="department-time">
                        <h6 class="text-muted text-wrap">Местное время: {{ selectedFlightItem.time }}</h6>
                      </span>
                    </b-row>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <v-calendar
        class="calendar"
        title-position="left"
        :attributes="attributes"
        :theme-styles="themeStyles"
        @dayclick="dateClicked"
      />
    </div>
  </b-card>
</template>

<script>
import moment from 'moment';
import {numberToRUFormat} from '@/components/mixins/helpers';
import Vue from 'vue';

export default {
  name: 'TenderFlightSchedule',
  props: {
    routeWays: {
      type: Array,
      require: false,
      default: () => {
        return {};
      },
    },
    tenderAdd: {
      type: Boolean,
      require: false,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      routeWaysArr: null,
      selectedScheduleDate: null,
      scheduleDates: [],
      scheduleFlights: [],
      scheduleAttributesDates: [],
      attributes: [],
      dateSelected: false,
      dateWithFlights: false,
      themeStyles: {
        wrapper: {
          backgroundColor: null,
        },
      },
    };
  },
  computed: {
    getMonth() {
      return (month) => {
        switch (month) {
        case 1:
          return 'Января';
          break;
        case 2:
          return 'Февраля';
          break;
        case 3:
          return 'Марта';
          break;
        case 4:
          return 'Апреля';
          break;
        case 5:
          return 'Мая';
          break;
        case 6:
          return 'Июня';
          break;
        case 7:
          return 'Июля';
          break;
        case 8:
          return 'Августа';
          break;
        case 9:
          return 'Сентября';
          break;
        case 10:
          return 'Октября';
          break;
        case 11:
          return 'Ноября';
          break;
        case 12:
          return 'Декабря';
          break;
        default:
          break;
        }
      };
    },
  },
  watch: {
    routeWays: {
      handler: function(newVal, oldVal) {
        oldVal = newVal;
        this.routeWaysArr = null;
        this.selectedScheduleDate = null;
        this.scheduleDates = [];
        this.scheduleFlights = [];
        this.scheduleAttributesDates = [];
        this.attributes = [];

        this.tenderAdd = false;

        this.routeWaysArr = newVal.map((item) => {
          return JSON.parse(JSON.stringify(item));
        });
        this.routeWaysArr.forEach((routeWay, index) => {
          const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
          for (let i = 0; i < days.length; i++) {
            if (routeWay.weekTemplate[days[i]].length > 0) {
              for (let j = 0; j < routeWay.weekTemplate[days[i]].length; j++) {
                const startDate = moment(routeWay.startDate).format('YYYY-MM-DD');
                const stopDate = moment(routeWay.stopDate).format('YYYY-MM-DD');
                const start = moment(startDate);
                const end = moment(stopDate);
                const currentDay = i;

                const result = [];
                const current = start.clone();

                if (current.day(currentDay).isSameOrBefore(end)) {
                  result.push(current.clone());
                }
                while (current.day(7 + currentDay).isSameOrBefore(end)) {
                  result.push(current.clone());
                }

                if (routeWay.points) {
                  routeWay.department = [];
                  routeWay.points.sort((a, b) => b.rowNumber - a.rowNumber).forEach((routeWayPoint) => {
                    routeWay.department.push(routeWayPoint.department.name);
                  });
                }
                result.forEach((m) => {
                  this.scheduleDates.push(m.format('YYYY-MM-DD'));
                  this.scheduleFlights.push({
                    code: routeWay.routeWayCode,
                    routeWayNumber: routeWay.routeNumber + 1,
                    date: m.format('YYYY-MM-DD'),
                    time: routeWay.weekTemplate[days[i]][j].time,
                    routePrice: routeWay.routePrice,
                    department: routeWay.department,
                  });
                });
              }
            }
          }
        });
        if (this.scheduleDates.length > 0) {
          const filteredScheduleDates = [...new Set(JSON.parse(JSON.stringify(this.scheduleDates)))];
          this.scheduleAttributesDates = filteredScheduleDates.map((item, index) => {
            return {
              id: index,
              date: item,
              time: this.scheduleFlights[index].time,
              code: this.scheduleFlights[index].code,
              routeWayNumber: this.scheduleFlights[index].routeWayNumber,
              routePrice: this.scheduleFlights[index].routePrice,
              department: this.scheduleFlights[index].department,
            };
          });
          this.attributes = this.scheduleAttributesDates.map((scheduleAttributesDate) => {
            return {
              key: `scheduleAttributeDate.${scheduleAttributesDate.id}`,
              dot: {
                diameter: '9px',
                backgroundColor: 'red',
                borderColor: null,
                borderStyle: 'solid',
                borderRadius: '50%',
                opacity: 1,
                margin: '-5px',
              },
              popover: {
                label: this.popoverLabelNumWord(this.scheduleDates.filter((item) => item === scheduleAttributesDate.date).length, ['рейс', 'рейса', 'рейсов']),
                visibility: 'hover',
              },
              dayPopoverContent: {color: 'white'},
              contentStyle: {
                border: '0px',
                fontWeight: '600',
                paddingTop: '12px',
                paddingBottom: '0px',
                marginTop: '-4px',
                marginBottom: '10px',
              },
              contentHoverStyle: {
                cursor: 'pointer',
              },
              dates: scheduleAttributesDate.date,
              code: scheduleAttributesDate.code,
              number: scheduleAttributesDate.routeWayNumber,
              customData: scheduleAttributesDate,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setHighlights();
  },
  methods: {
    dateClicked(day) {
      this.setHighlights();
      this.dateSelected = true;
      this.selectedScheduleDate = day;

      const selectedFlights = this.scheduleFlights.filter((item) => (item.date == moment(this.selectedScheduleDate.date).format('YYYY-MM-DD')));
      const selectedFlightsSorted = {};
      selectedFlights.forEach((item) => {
        selectedFlightsSorted[item.routeWayNumber] = [];
      });
      selectedFlights.forEach((item) => {
        selectedFlightsSorted[item.routeWayNumber].push(item);
      });

      Vue.set(this.selectedScheduleDate, 'selectedFlights', selectedFlightsSorted);
      (this.selectedScheduleDate != null && this.selectedScheduleDate.attributes.length > 0) ? this.dateWithFlights = true : this.dateWithFlights = false;
    },
    setHighlights() {
      document.querySelectorAll('.c-day-content').forEach((item) => {
        item.addEventListener('click', () => {
          document.querySelectorAll('.c-day-content').forEach((item) => { item.style = ''; });
          item.style = 'background-color: #20a8d8; color: white; font-weight: 600; width: 23px; height: 23px; margin-bottom: 6px;';
        });
      });
    },
    labelNumWord(value, words) {
      const val = Math.abs(value) % 100;
      const num = val % 10;

      let word = words[2];

      (value > 10 && value < 20) && (word = words[2]);
      (num > 1 && num < 5) && (word = words[1]);
      (num == 1) && (word = words[0]);

      return `${value} ${word}`;
    },
    popoverLabelNumWord(value, words) {
      return `${this.labelNumWord(value, words)} в этот день`;
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
  },
};
</script>

<style lang="scss" scoped>
$schedule-min-height: 400px;
$pseudo-line-radius: 50px;
$line-color: #20a8d8;

.c-day-dots {
  margin: -2px !important;
}

.schedule {
  display: flex;
  border-radius: 15px !important;
  min-height: $schedule-min-height;
  width: 100%;
}

.calendar {
  padding: 0 30px 0 30px;
  width: 70%;
}

.schedule-routes {
  display: flex;
  width: 100%;
}

.schedule-route {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: gray;
  scrollbar-width: thin;
  -moz-scrollbar-color: gray;
  -moz-scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    height: 20px;
    margin-top: 30px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 240px;
    margin-top: 10px;
    justify-content: flex-start;
  }
}

.schedule-route__body-item {
  border-left: 4px solid #c41e3a;
  padding: 3px 48px 3px 38px;
  // box-sizing: border-box;

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2px;
    width: 100%;
    background-color: $line-color;

    &::before {
      content: '';
      display: block;
      height: 5px;
      width: 40px;
      margin-top: -1px;
      -webkit-border-top-right-radius: $pseudo-line-radius;
      -webkit-border-bottom-right-radius: $pseudo-line-radius;
      -moz-border-radius-topright: $pseudo-line-radius;
      -moz-border-radius-bottomright: $pseudo-line-radius;
      border-top-right-radius: $pseudo-line-radius;
      border-bottom-right-radius: $pseudo-line-radius;
      background-color: $line-color;
    }

    &::after {
      content: '';
      display: block;
      height: 5px;
      width: 40px;
      margin-top: -1px;
      -webkit-border-top-left-radius: $pseudo-line-radius;
      -webkit-border-bottom-left-radius: $pseudo-line-radius;
      -moz-border-radius-topleft: $pseudo-line-radius;
      -moz-border-radius-bottomleft: $pseudo-line-radius;
      border-top-left-radius: $pseudo-line-radius;
      border-bottom-left-radius: $pseudo-line-radius;
      background-color: $line-color;
    }
  }
}

.middle-routes-label {
  position: relative;
  bottom: 5px;
  font-weight: 600;
  color: $line-color;
}

.schedule-routes__not-found {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  h4 {
    white-space: pre-wrap;
  }
}

.department {
  min-width: 150px;
  width: 60%;

  &-text {
    width: 180px;
  }

  &-time {
    position: relative;
    top: 10px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .7s;
}
.fade-enter, .fade-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
