<template>
  <div>
    <div v-if="dimension && optionalDimensions && optionalDimensions.length > 0">
      <b-form-group
        v-if="dimension"
        label="Основные габариты ТС"
      >
        {{ dimension.name }} {{ numberRUFormat(Math.round(dimension.percentage/100*price)) }} руб.
      </b-form-group>
      <b-form-group
        v-if="optionalDimensions && optionalDimensions.length > 0"
        label="Дополнительные габариты ТС"
      >
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedOptionalDimensionsIds"
          stacked
          @change="emitValue()"
        >
          <b-form-checkbox
            v-for="optionalDimension in optionalDimensions"
            :key="optionalDimension.id"
            :value="optionalDimension.id"
          >
            {{ optionalDimension.name }}
            {{ numberRUFormat(Math.round(optionalDimension.percentage/100*price)) }} руб.
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </div>
    <div v-else>
      <h5>Нет данных</h5>
    </div>
  </div>
</template>

<script>
import {routeWayDimensionForRouteWay} from '@/services/api';
import {numberToRUFormat} from '@/components/mixins/helpers';

export default {
  name: 'DimensionsEdit',
  props: ['value', 'price', 'index'],
  data() {
    return {
      dimension: null,
      optionalDimensions: [],
      selectedOptionalDimensionsIds: [],
    };
  },
  computed: {
    selectedDimension() {
      return {...this.value, ...{dimension: this.dimension,
        optionalDimensions: this.optionalDimensions.map((dimension) => {
          return {dimension: dimension, active: this.selectedOptionalDimensionsIds.includes(dimension.id)};
        }),
      }};
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.dimension = this.value.dimension;
          if (this.value.optionalDimensions && this.value.optionalDimensions.length > 0 || this.value.dimension != null) {
            this.selectedOptionalDimensionsIds = [];
            this.optionalDimensions = [];
            this.value.optionalDimensions.forEach((dimension) => {
              this.optionalDimensions.push(dimension.dimension);
              if (dimension.active) {
                return this.selectedOptionalDimensionsIds.push(dimension.dimension.id);
              }
            });
          } else {
            this.selectedOptionalDimensionsIds = [];
            if (this.value.id) {
              this.getRouteWayDimension(true);
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitValue() {
      this.$emit('input', {...JSON.parse(JSON.stringify(this.selectedDimension))}, this.index);
    },
    async getRouteWayDimension(idChanged = false) {
      const response = await routeWayDimensionForRouteWay(this.$store.state.user.ownerId, this.value.id);
      if (response && response.status === 200) {
        this.dimension = response.data.dimension;
        this.optionalDimensions = response.data.optionalDimensions;
        if (this.value?.optionalDimensions?.length == 0 || idChanged) {
          this.selectedOptionalDimensionsIds = response.data.optionalDimensions.map((dimension) => {
            return dimension.id;
          });
          this.$emit('input', {...JSON.parse(JSON.stringify(this.selectedDimension))}, this.index);
        }
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
  },
};
</script>
