<template>
	<div class="animated fadeIn tender-page">
		<h4 class="tender-page__title">
			Создание тендера
		</h4>
		<b-form novalidate @submit.prevent="onSubmit">
			<b-form-group
				id="usernameInputGroup1"
				label="Название тендера"
				label-for="name"
				description="Укажите название, которое будет отображаться в заголовке тендера у перевозчиков."
			>
				<b-form-input
					id="name"
					v-model.trim="$v.form.name.$model"
					type="text"
					name="name"
					:state="chkState('name')"
					aria-describedby="input1LiveFeedback1"
					placeholder="Название тендера"
					autocomplete="name"
					autofocus
				/>
				<b-form-invalid-feedback id="input1LiveFeedback1">
					Это поле обязательно для заполнения, максимальное кол-во
					символов 150
				</b-form-invalid-feedback>
			</b-form-group>
			<b-form-group
				id="organizationsInputGroup"
				class="form-group-el-select"
				label="Организация"
				label-for="organizationSelect"
			>
				<el-select-clearable
					v-model="form.organizations.value"
					class="form-control"
					name="organizations"
					placeholder="Выберите организацию"
					clearable
					:state="chkState('organizations')"
					:filterable="true"
					:remote="true"
					reserve-keyword
					:remote-method="getOrganizationsList"
					:loading="loading"
					@clear="getOrganizationsList"
				>
					<el-option
						v-for="item in form.organizations.options"
						:key="item.value"
						:label="item.text"
						:value="item.value"
					/>
					<b-form-invalid-feedback id="input1LiveFeedback2">
						Это поле обязательно для заполнения
					</b-form-invalid-feedback>
				</el-select-clearable>
			</b-form-group>

      <b-form-group
					id="additionalWayPointInputGroup1"
					label="Стоимость заезда в доп. точку"
					label-for="additionalWayPointPrice"
					description="Формат заполнения ячейки - 10000. Валюта - рубли."
			>
					<b-form-input
							id="additional-point-price"
							v-model="form.additionalWayPointPrice"
							type="number"
							name="additional-way-point-price"
							min="1"
							placeholder=""
							autocomplete="additional-way-point-price"
						  @wheel="$event.target.blur()"
					/>
			</b-form-group>
			
			<div class="custom-fieldset">
				<tender-flight-schedule
					:key="form.routeWay"
					:route-ways="form.routeWays"
					:tender-add="true"
				/>
			</div>
			<div class="form-sections">
				<div
					v-for="(routeWay, index) in form.routeWays"
					:key="`route-way-${index}`"
					class="custom-fieldset"
				>
					<h5 class="custom-fieldset__title">
						<i class="fa fa-map-o fa-lg mr-2" />Маршрут и расписание
						№{{ index + 1 }}
						<b-button
							v-if="form.routeWays.length > 1"
							type="button"
							variant="danger"
							:disabled="loading"
							:class="{ loading: loading }"
							class="route-and-flight-details__delete-route-way"
							@click="deleteRouteWay(index)"
						>
							Удалить маршрут
						</b-button>
					</h5>
					<div class="route-and-flight-details">
						<div class="route-and-flight-details__route-container">
							<h6
								class="route-and-flight-details__route-container-title route-and-flight-details__title custom-fieldset__title"
							>
								Маршрут тендера:
							</h6>
							<b-row>
								<b-col md="4">
									<b-form-group
										class="form-group-el-select"
										label="Код маршрута"
									>
										<el-select
											v-model="
												routeWay.playedRouteWay.value
											"
											class="form-control"
											name="responsible"
											placeholder=""
											clearable
											:filterable="true"
											:remote="true"
											reserve-keyword
											:remote-method="routeWaysShortlist"
											:loading="loading"
											@focus="
												data.focusedPlayedRouteWaySelect = index
											"
											@change="
												routeWayRead(
													index,
													routeWay.playedRouteWay
														.value
												)
											"
											@clear="
												routeWaysShortlist('', index)
											"
										>
											<el-option
												v-for="item in routeWay
													.playedRouteWay.options"
												:key="item.value"
												:label="item.text"
												:value="item.value"
											/>
										</el-select>
										<small
											v-if="
												data.routeWays[index]
													.fullDistance
											"
											class="form-text text-muted"
											>Расстояние:
											{{
												data.routeWays[index]
													.fullDistance
											}}
											км.</small
										>
										<small
											v-else
											class="form-text text-muted"
											>Формат заполнения ячейки -
											МВМСЧБ</small
										>
										<small
											v-if="
												data.routeWays[index].timeSummer
											"
											class="form-text text-muted"
										>
											Ориентировочное время:
											{{
												data.routeWays[index].timeSummer
											}}
										</small>
										<small v-if="
												data.routeWays[index].timeSummer
											" class="text-muted">
											<strong>*</strong> Точное время доставки будет указано в транспортной накладной после отправки.</small>
									</b-form-group>
									<b-form-group
										v-if="routeWay.playedRouteWay.value"
										class="form-group-el-select"
										label="Тип перевозки"
									>
										<el-select
											v-model="
												routeWay.routeTransportations
													.value
											"
											class="form-control"
											clearable
											:filterable="true"
											reserve-keyword
											:loading="loading"
										>
											<el-option
												v-for="item in routeWay
													.routeTransportations
													.options"
												:key="item.value"
												:label="item.text"
												:value="item.value"
											/>
										</el-select>
									</b-form-group>
									<div
										v-if="
											data.routeWays[index].points
												.length > 0
										"
									>
										<b>Точки маршрута:</b>
										<ol class="pl-0 list-inside">
											<li
												v-for="point in data.routeWays[
													index
												].points"
												:key="
													`${index}-route-way-point-${point.id}`
												"
											>
												{{
													point.department
														.mainWarehouse.address
												}}
											</li>
										</ol>
										<b>Вид перевозки:</b>
										<p class="mb-3">
											{{
												data.routeWays[index]
													.transportationType.name
											}}
											<i
												v-if="
													data.routeWays[index]
														.transportationType
														.id == 1
												"
												class="fa fa-plane fa-md"
											/>
											<i
												v-if="
													data.routeWays[index]
														.transportationType
														.id == 2
												"
												class="fa fa-truck fa-md"
											/>
											<i
												v-if="
													data.routeWays[index]
														.transportationType
														.id == 3
												"
												class="fa fa-subway fa-md"
											/>
											<i
												v-if="
													data.routeWays[index]
														.transportationType
														.id == 4
												"
												class="fa fa-ban fa-md"
											/>
										</p>
									</div>
								</b-col>
								<b-col md="4">
									<b-form-group
										id="usernameInputGroup1"
										label="Стоимость для одного рейса по маршруту"
										label-for="name"
										description="Формат заполнения ячейки - 10000. Валюта - рубли."
									>
										<b-form-input
											id="route-price"
											v-model="routeWay.routePrice"
											type="number"
											name="route-price"
											min="1"
											placeholder=""
											:disabled="routeWay.autoTariff"
											autocomplete="route-price"
											@wheel="$event.target.blur()"
										/>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
										id="usernameInputGroup2"
										label="Шаг ставки"
										label-for="name"
										description="Формат заполнения ячейки - 100. Валюта - рубли."
									>
										<b-form-input
											id="step-rate"
											v-model="routeWay.stepRate"
											type="number"
											name="step-rate"
											min="1"
											placeholder=""
											autocomplete="route-price"
											@wheel="$event.target.blur()"
										/>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
										label="Получать стоимость тендера автоматически"
									>
										<b-form-checkbox
											v-model="routeWay.autoTariff"
											name="autoTariff"
											@change="
												customersRouteTariffTenderRead(
													index
												)
											"
										>
											Да
										</b-form-checkbox>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
								        label="Вид маршрута"
									>
										<b-form-radio-group v-model="routeWay.routeType">
											<b-form-radio
												v-model="routeWay.routeType"
												name="routeType"
												value="oneWay"
											>
												В один конец
											</b-form-radio>
											<b-form-radio
												v-model="routeWay.routeType"
												name="routeType"
												value="circular"
											>
												Круговой
											</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
						<div class="route-and-flight-details__transport">
							<h6
								class="route-and-flight-details__title custom-fieldset__title"
							>
								Требования к транспорту
							</h6>
							<p>
								Укажите требования для транспортных средств в
								рейсе
							</p>
							<b-row>
								<b-col md="4">
									<b-form-group
										class="route-and-flight-details__form-group"
										label="Габариты"
									>
										<dimensions-add
											v-model="routeWay.dimensions"
											:price="routeWay.routePrice"
											:index="routeWay"
											@input="
												getSelectedDimensions(
													$event,
													index
												)
											"
										/>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
										class="route-and-flight-details__form-group form-group-el-select"
										label="Погрузка/Выгрузка"
									>
										<b-form-checkbox-group
											v-model="
												routeWay.loadingUnloading.value
											"
											name="loading-unloading"
											stacked
										>
											<b-form-checkbox
												v-for="(item,
												optionIndex) in form
													.loadingUnloading.options"
												:key="optionIndex"
												:value="item.value"
											>
												{{ item.text }}
											</b-form-checkbox>
										</b-form-checkbox-group>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
										class="route-and-flight-details__form-group form-group-el-select"
										label="Тип кузова"
									>
										<b-form-checkbox-group
											v-model="routeWay.bodyType.value"
											name="body-type"
											stacked
										>
											<b-form-checkbox
												v-for="(item,
												optionIndex) in form.bodyType
													.options"
												:key="optionIndex"
												:value="item.value"
											>
												{{ item.text }}
											</b-form-checkbox>
										</b-form-checkbox-group>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
						<div class="route-and-flight-details__route-container">
							<h6
								class="route-and-flight-details__route-container-title route-and-flight-details__title custom-fieldset__title"
							>
								Расписание:
							</h6>
							<p>
								Укажите диапазон в рамках которого будут
								проводиться перевозки
							</p>
							<b-row>
								<b-col md="4">
									<b-form-group label="Дата начала маршрута">
										<el-date-picker-input
											v-model="routeWay.startDate"
											type="date"
											format="dd.MM.yyyy"
											placeholder="дд.мм.гггг"
											:picker-options="pickerOptions"
											@change="
												tenderComputing(index, 'start')
											"
											@clear="
												tenderComputing(index, 'start')
											"
										/>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<b-form-group
										label="Дата окончания маршрута"
									>
										<el-date-picker-input
											v-model="routeWay.stopDate"
											type="date"
											format="dd.MM.yyyy"
											placeholder="дд.мм.гггг"
											:picker-options="pickerOptions"
											@change="
												tenderComputing(index, 'stop')
											"
											@clear="
												tenderComputing(index, 'stop')
											"
										/>
									</b-form-group>
								</b-col>
								<b-col md="4">
									<h6 class="custom-fieldset__subtitle">
										Количество недель
									</h6>
									<p>{{ data.routeWays[index].weekCount }}</p>
								</b-col>
							</b-row>
							<h6
								class="route-and-flight-details__route-container-title route-and-flight-details__subtitle custom-fieldset__subtitle"
							>
								Расписание на неделю
							</h6>
							<p>
								Укажите дни недели проведения перевозок, и на
								каждый активный день задайте время прибытия в
								первую точку маршрута.
							</p>
							<div class="route-way-week">
								<div class="route-way-week__head">
									<button
										:class="{
											active:
												routeWay.weekTemplate.monday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[1]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(index, 'monday')
										"
									>
										ПН
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.tuesday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[2]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(index, 'tuesday')
										"
									>
										ВТ
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.wednesday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[3]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(
												index,
												'wednesday'
											)
										"
									>
										СР
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.thursday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[4]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(
												index,
												'thursday'
											)
										"
									>
										ЧТ
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.friday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[5]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(index, 'friday')
										"
									>
										ПТ
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.saturday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[6]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(
												index,
												'saturday'
											)
										"
									>
										СБ
									</button>
									<button
										:class="{
											active:
												routeWay.weekTemplate.sunday
													.length > 0,
										}"
										:disabled="
											!Boolean(
												data.routeWays[index]
													.weekdaysCounters[0]
											)
										"
										type="button"
										class="route-way-week__head-item"
										@click="
											switchRouteWayWeek(index, 'sunday')
										"
									>
										ВС
									</button>
								</div>
								<div class="route-way-week__body">
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.monday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'monday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'monday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.tuesday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'tuesday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'tuesday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.wednesday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'wednesday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'wednesday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.thursday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'thursday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'thursday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.friday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'friday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'friday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.saturday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'saturday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'saturday'
												)
											"
										/>
									</div>
									<div class="route-way-week__body-weekday">
										<label
											v-for="(item,
											weekItemIndex) in routeWay
												.weekTemplate.sunday"
											:key="
												`route-way-week-item-${weekItemIndex}`
											"
											class="route-way-week__body-item"
										>
											<b-form-input
												v-model="item.time"
												type="time"
												placeholder="00:00"
												class="route-way-week__body-time"
											/>
											<button
												type="button"
												class="route-way-week__body-remove"
												@click="
													removeRouteWayWeekItem(
														index,
														'sunday',
														weekItemIndex
													)
												"
											/>
										</label>
										<button
											type="button"
											class="route-way-week__body-add"
											@click="
												createRouteWayWeekItem(
													index,
													'sunday'
												)
											"
										/>
									</div>
								</div>
							</div>
							<p>
								Заданная конфигурация будет применима ко всем
								неделям в рамках диапазона перевозок
							</p>
							<p v-if="data.routeWays[index].routeCount">
								Всего рейсов по маршруту -
								{{ data.routeWays[index].routeCount }}
							</p>
						</div>
					</div>
          <b-col xl="5" md="7" sm="8" v-if="form.routeWays[index].comment.visible">
              <b-form-textarea
            class="mt-4"
            id="comment-area"
            name="comment-area"
            placeholder="Пожалуйста, введите комментарий (до 200 символов)"
            no-resize
            rows="5"
            maxlength = "200"
            v-model="routeWay.comment.value"
            >
            </b-form-textarea>
            </b-col>
            <b-button
            type="button"
						variant="primary"
						class="ml-3 mt-4"
            @click="addComment(index)"
            v-if="form.routeWays[index].comment.visible === false"
            >
              <i class="fa fa-plus" />&nbsp; &nbsp; Добавить комментарий
            </b-button>

			          <b-button
								type="button"
								variant="success"
								class="ml-3 mt-4"
								v-if="form.routeWays[index].comment.visible && !updateButton"
                				:disabled="!routeWay.comment.value"
								@click="saveComment(index)"
								>
								Сохранить
								</b-button>

								<b-button
								type="button"
								variant="success"
								class="ml-3 mt-4"
								v-if="form.routeWays[index].comment.visible && updateButton"
                				:disabled="!routeWay.comment.value"
								@click="updateComment(index)"
								>
								Обновить комментарий
								</b-button>

            <b-button
            type="button"
            variant="danger"
            class="ml-3 mt-4"
            v-if="form.routeWays[index].comment.visible && !updateButton"
            @click="deleteComment(index)"
            >
              Удалить комментарий
            </b-button>
			<b-button
            type="button"
            variant="danger"
            class="ml-3 mt-4"
            v-if="form.routeWays[index].comment.visible && updateButton"
            @click="destroyComment(index)"
            >
              Удалить комментарий
            </b-button>
					<div class="route-and-flight-details__buttons mt-4">
						<b-button
							type="button"
							variant="primary"
							:disabled="loading"
							:class="{ loading: loading }"
							class="route-and-flight-details__add-route-way"
							@click="addRouteWay"
						>
							Добавить еще маршрут
						</b-button>
					</div>
				</div>
				<div class="custom-fieldset">
					<h5 class="custom-fieldset__title">
						<i class="fa fa-gavel fa-lg mr-2" />Тендер
					</h5>
					<b-tabs>
						<b-tab
							class="terms-and-participants"
							title="Условия и участники"
						>
							<b-row no-gutters>
								<b-col lg="8">
									<div
										class="terms-and-participants__contractors"
									>
										<h6
											class="terms-and-participants__title"
										>
											Участвующие перевозчики
										</h6>
										<p>
											Укажите списки перевозчиков для
											которых доступен тендер
										</p>
										<b-form-group
											class="form-group-el-select"
										>
											<el-select
												v-model="
													form.listOfContractors.value
												"
												class="form-control"
												name="route-way"
												placeholder="Добавить группу перевозчиков"
												clearable
												reserve-keyword
												:loading="loading"
												@change="
													onContractorsListChoose(
														form.listOfContractors
															.value
													)
												"
											>
												<el-option
													v-for="item in form
														.listOfContractors
														.options"
													:key="item.value"
													:label="item.text"
													:value="item.value"
												/>
											</el-select>
										</b-form-group>
										<b-form-group
											class="form-group-el-select"
										>
											<el-select
												v-model="form.contractors.value"
												class="form-control"
												name="route-way"
												placeholder="Добавить перевозчика"
												:filterable="true"
												:remote="true"
												reserve-keyword
												:remote-method="
													contractorRemote
												"
												:loading="loading"
												@focus="contractorRemote('')"
												@change="
													onContractorChoose(
														form.contractors.value
													)
												"
											>
												<el-option
													v-for="item in form
														.contractors.options"
													:key="item.value"
													:label="item.text"
													:value="item.value"
													:disabled="item.disabled"
												/>
											</el-select>
										</b-form-group>
										<v-client-table
											id="dataTable"
											:data="contractors.items"
											:columns="contractors.columns"
											:options="contractors.options"
											:theme="contractors.theme"
										>
											<span
												slot="rating.rating"
												slot-scope="props"
											>
												<span v-if="props.row.rating"
													><span
														:class="{
															'rating-low':
																props.row.rating
																	.rating <=
																30,
															'rating-mid':
																props.row.rating
																	.rating >=
																	31 &&
																props.row.rating
																	.rating <=
																	49,
															'rating-high':
																props.row.rating
																	.rating >=
																50,
														}"
														>{{
															props.row.rating
																.rating
														}}</span
													>
												</span>
											</span>
											<span
												slot="actions"
												slot-scope="props"
											>
												<a
													v-if="
														$store.getters.isAdmin
													"
													v-b-tooltip.hover.bottom="
														'Удалить'
													"
													class="fa fa-close fa-lg text-danger table-action-button"
													@click="
														deleteChosenContractor(
															props.row.id
														)
													"
												/>
											</span>
										</v-client-table>
										<div class="d-flex justify-content-end">
											<b-button
												type="button"
												variant="danger"
												:class="{ loading: loading }"
												:disabled="
													loading ||
														!contractors.items
															.length
												"
												@click="clearContractorsList()"
											>
												Очистить список перевозчиков
											</b-button>
										</div>
									</div>
								</b-col>
								<b-col lg="4">
									<div
										class="terms-and-participants__start-price"
									>
										<h6
											class="terms-and-participants__title"
										>
											Первоначальная стоимость лота
										</h6>
										<p>
											Сформирована из всех добавленных
											маршрутов
										</p>
										<b-row>
											<b-col xl="6" lg="12" md="6">
												<b-form-group>
													<b-form-input
														v-model="totalPrice"
														type="number"
														autocomplete="false"
														disabled
													/>
												</b-form-group>
											</b-col>
											<b-col xl="6" lg="12" md="6">
												<b-form-group
													class="form-group-el-select"
													:description="
														alternativeAmountValue(
															form.VAT.value,
															totalPrice
														)
													"
												>
													<el-select
														v-model="form.VAT.value"
														class="form-control"
														name="route-way"
														:loading="loading"
													>
														<el-option
															v-for="item in form
																.VAT.options"
															:key="item.value"
															:label="item.text"
															:value="item.value"
														/>
													</el-select>
												</b-form-group>
											</b-col>
										</b-row>
										<div
											class="terms-and-participants__tender-duration"
										>
											<h6
												class="terms-and-participants__title"
											>
												Период проведения тендера
											</h6>
											<p>
												Период проведения тендера
												назначается непосредственно
												перед запуском. Для указания
												времени воспользуйтесь кнопкой
												"Сохранить и запустить тендер"
											</p>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</div>
			<div class="fixed-buttons">
				<b-button
					type="button"
					variant="secondary"
					class="mr-2"
					:class="{ loading: loading }"
					@click="$router.go(-1)"
				>
					Назад
				</b-button>
				<b-button
					type="submit"
					variant="primary"
					class="mr-2"
					:disabled="$v.form.$invalid || loading"
					:class="{ loading: loading }"
				>
					Создать тендер
				</b-button>
				<b-button
					type="button"
					variant="success"
					:disabled="$v.form.$invalid || loading"
					:class="{ loading: loading }"
					@click="startTenderModal.show = true"
				>
					Создать и запустить тендер
				</b-button>
			</div>
		</b-form>

		<b-modal
			v-model="startTenderModal.show"
			:title="'Запуск тендера ' + form.name"
			class="modal-warning"
			cancel-title="Назад"
			ok-variant="success"
			:ok-disabled="startTenderDisabled"
			ok-title="Запустить тендер"
			@ok="onStart()"
		>
			<p>Выберите тип запуска тендера и назначьте время</p>
			<b-form-group class="form-group-el-select" label="Способ запуска">
				<el-select
					v-model="startTenderModal.method.value"
					class="form-control"
					name="route-way"
					:loading="loading"
				>
					<el-option
						v-for="item in startTenderModal.method.options"
						:key="item.value"
						:label="item.text"
						:value="item.value"
					/>
				</el-select>
			</b-form-group>
			<b-form-group
				v-if="startTenderModal.method.value"
				label="Старт тендера"
				label-for="start-tender-modal-start-date"
			>
				<el-date-picker-input
					id="start-tender-modal-start-date"
					v-model="startTenderModal.startDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
				/>
			</b-form-group>
			<b-form-group
				label="Окончание приема предложений"
				label-for="start-tender-modal-end-date"
			>
				<el-date-picker-input
					id="start-tender-modal-end-date"
					v-model="startTenderModal.stopDate"
					type="datetime"
					placeholder=""
					format="dd.MM.yyyy HH:mm"
					:picker-options="pickerOptions"
				/>
			</b-form-group>
			<b-form-group>
				<b-form-checkbox
					id="enableOffers"
					v-model="startTenderModal.enableOffers"
					name="enable-offers"
				>
					Возможность подачи встречных предложений
				</b-form-checkbox>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import DimensionsAdd from "../../components/DimensionsAdd.vue";
import TenderFlightSchedule from "../../components/TenderFlightSchedule.vue";
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import { validationMixin } from "vuelidate";
import { required, maxLength, between } from "vuelidate/lib/validators";
import notifications from "../../components/mixins/notifications";
import {
	routeWaysShortlist,
	routeWayRead,
	transportVehiclesAuctionBodyTypes,
	transportVehiclesLoadingTypes,
	transportVehiclesDimensionsTypes,
	customersContractorsListsList,
	customersContractorsListRead,
	customersContractorsList,
	customersContractorRead,
	customersRouteTariffTenderRead,
	customersTenderCreate,
	customersTenderCreateAndRun,
	organizationsList,
} from "../../services/api";
import ElDatePickerInput from "@/components/ElDatePickerInput";
import {
	valueToValueWithVAT,
	valueToValueWithoutVAT,
} from "@/components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";
import DimensionsEdit from "@/components/DimensionsEdit";

Vue.use(ClientTable);

export default {
	name: "CustomerTendersCreate",
	components: {
		DimensionsEdit,
		ClientTable,
		Event,
		ElDatePickerInput,
		ElSelectClearable,
		TenderFlightSchedule,
		DimensionsAdd,
	},
	mixins: [validationMixin, notifications],
	data() {
		return {
      updateButton: null,
			data: {
				routeWays: [
					{
						firstRouteWayTimezone: null,
						fullDistance: null,
						timeSummer: null,
						points: [],
						weekCount: null,
						routeCount: null,
						weekdaysCounters: [],
					},
				],
				focusedPlayedRouteWaySelect: null,
				defaultDimension: null,
				defaultLoadingUnloading: [],
				defaultBodyType: [],
				totalPrice: null,
			},
			form: {
				name: null,
        additionalWayPointPrice: null,
				size: {
					options: [],
				},
				loadingUnloading: {
					options: [],
				},
				bodyType: {
					options: [],
				},
				organizations: {
					value: null,
					options: [],
				},
				routeWays: [
					{
						comment: {
							visible: false,
							value: null,
						},
						playedRouteWay: {
							options: [],
							value: "",
						},
						routeTransportations: {
							value: "",
							options: [],
						},
						dimension: null,
						optionalDimensions: null,
						routeNumber: null,
						routeWayCode: null,
						routePrice: null,
						routeType: "oneWay",
						stepRate: 500,
						autoTariff: true,
						startDate: null,
						stopDate: null,
						department: [],
						weekTemplate: {
							monday: [],
							tuesday: [],
							wednesday: [],
							thursday: [],
							friday: [],
							saturday: [],
							sunday: [],
						},
						size: {
							value: null,
						},
						loadingUnloading: {
							value: [],
						},
						bodyType: {
							value: [],
						},
					},
				],
				listOfContractors: {
					options: [],
					value: null,
				},
				contractors: {
					options: [],
					value: null,
				},
				VAT: {
					options: [
						{ value: 0, text: "Без НДС" },
						{ value: 1, text: "С НДС" },
					],
					value: 1,
				},
			},
			contractors: {
				columns: [
					"id",
					"name",
					"email",
					"trucksCount",
					"rating.rating",
					"actions",
				],
				options: {
					headings: {
						id: "ID",
						name: "Название",
						email: "Email",
						trucksCount: "Кол-во автомобилей",
						"rating.rating": "Рейтинг",
						actions: "",
					},
					sortable: [
						"id",
						"name",
						"email",
						"trucksCount",
						"rating.rating",
					],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					perPage: 5,
					perPageValues: [5, 10, 25],
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				items: [],
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			startTenderModal: {
				show: false,
				method: {
					options: [
						{ value: 0, text: "Запустить сейчас" },
						{ value: 1, text: "Запустить как отложенный" },
					],
					value: 0,
				},
				startDate: "",
				stopDate: "",
				enableOffers: true,
			},
			loading: false,
			useVuex: false,
			template: "default",
		};
	},
	computed: {
		totalPrice() {
			let totalPrice = 0;
			this.data.routeWays.forEach((item, index) => {
				totalPrice =
					totalPrice +
					item.routeCount * this.form.routeWays[index].routePrice;
			});
			return totalPrice;
		},
		startTenderDisabled() {
			if (this.startTenderModal.method.value) {
				return !(
					this.startTenderModal.startDate &&
					this.startTenderModal.stopDate
				);
			} else {
				return !this.startTenderModal.stopDate;
			}
		},
		formStrAdd() {
			const calculationFormData = {};
			if (this.form.name) {
				calculationFormData.name = this.form.name;
			}

      if (this.form.additionalWayPointPrice) {
        calculationFormData.additionalWayPointPrice = this.form.additionalWayPointPrice;
      }

			calculationFormData.withNDS = !!this.form.VAT.value;
			calculationFormData.possibleParticipants = this.contractors.items.map(
				(contractor) => {
					return { id: contractor.id };
				}
			);
			if (this.form.organizations.value) {
				calculationFormData.organization = {
					id: this.form.organizations.value,
				};
			}
			if (this.form.routeWays.length > 0) {
				calculationFormData.routeTemplates = this.form.routeWays.map(
					(routeWay, index) => {
						const routeTemplate = {
							routeWay: {},
						};
						routeTemplate.autoTariff = routeWay.autoTariff;

						if (routeWay.routeType) {
							routeTemplate.routeType = routeWay.routeType;
						}
						if (routeWay.playedRouteWay.value) {
							routeTemplate.routeWay = {
								id: routeWay.playedRouteWay.value,
							};
						}
						if (routeWay.routeTransportations.value) {
							routeTemplate.transportation = {
								id: routeWay.routeTransportations.value,
							};
						}
						if (routeWay.startDate) {
							routeTemplate.periodStart = moment(
								routeWay.startDate
							)
								.tz(
									this.data.routeWays[index]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						if (routeWay.stopDate) {
							routeTemplate.periodStop = moment(routeWay.stopDate)
								.tz(
									this.data.routeWays[index]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						// if (routeWay.size.value) {
						//   routeTemplate.dimension = {id: routeWay.size.value};
						// }
						routeTemplate.loadingTypes = routeWay.loadingUnloading.value.map(
							(item) => {
								return { id: item };
							}
						);
						routeTemplate.bodyTypes = routeWay.bodyType.value.map(
							(item) => {
								return { id: item };
							}
						);
						if (routeWay.routePrice) {
							routeTemplate.routeSumm = routeWay.routePrice;
						}
						if (routeWay.stepRate) {
							routeTemplate.step = routeWay.stepRate;
						}
						if (routeWay.comment.value && routeWay.comment.value !== "") {
							routeTemplate.commentValue = routeWay.comment.value;
						}
						if (routeWay.dimension) {
							routeTemplate.dimension = routeWay.dimension;
						}
						if (routeWay.optionalDimensions) {
							routeTemplate.optionalDimensions =
								routeWay.optionalDimensions;
						}
						if (routeWay.weekTemplate) {
							const allRoutes = [
								...routeWay.weekTemplate.monday,
								...routeWay.weekTemplate.tuesday,
								...routeWay.weekTemplate.wednesday,
								...routeWay.weekTemplate.thursday,
								...routeWay.weekTemplate.friday,
								...routeWay.weekTemplate.saturday,
								...routeWay.weekTemplate.sunday,
							];
							routeTemplate.routeDateTemplates = [];
							allRoutes.forEach((item) => {
								if (item.time)
									routeTemplate.routeDateTemplates.push({
										dateTemplate:
											item.weekday +
											" this week " +
											item.time,
									});
							});
						}
						return routeTemplate;
					}
				);
			}
			return calculationFormData;
		},
		formStrStart() {
			const calculationFormData = {};
			if (this.form.name) {
				calculationFormData.name = this.form.name;
			}

      if (this.form.additionalWayPointPrice) {
        calculationFormData.additionalWayPointPrice = this.form.additionalWayPointPrice;
      }

			if (this.startTenderModal.startDate) {
				calculationFormData.startDate = moment(
					this.startTenderModal.startDate
				).format();
			}
			if (this.startTenderModal.stopDate) {
				calculationFormData.stopDate = moment(
					this.startTenderModal.stopDate
				).format();
			}
			if (this.form.organizations.value) {
				calculationFormData.organization = {
					id: this.form.organizations.value,
				};
			}
			calculationFormData.enableOffers = this.startTenderModal.enableOffers;
			calculationFormData.withNDS = !!this.form.VAT.value;
			if (this.contractors.items.length > 0) {
				calculationFormData.possibleParticipants = this.contractors.items.map(
					(contractor) => {
						return { id: contractor.id };
					}
				);
			}
			if (this.form.routeWays.length > 0) {
				calculationFormData.routeTemplates = this.form.routeWays.map(
					(routeWay, index) => {
						const routeTemplate = {
							routeWay: {},
						};
						routeTemplate.autoTariff = routeWay.autoTariff;

						if (routeWay.routeType) {
							routeTemplate.routeType = routeWay.routeType;
						}

						if (routeWay.playedRouteWay.value) {
							routeTemplate.routeWay = {
								id: routeWay.playedRouteWay.value,
							};
						}
						if (routeWay.routeTransportations.value) {
							routeTemplate.transportation = {
								id: routeWay.routeTransportations.value,
							};
						}
						if (routeWay.startDate) {
							routeTemplate.periodStart = moment(
								routeWay.startDate
							)
								.tz(
									this.data.routeWays[index]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						if (routeWay.stopDate) {
							routeTemplate.periodStop = moment(routeWay.stopDate)
								.tz(
									this.data.routeWays[index]
										.firstRouteWayTimezone,
									true
								)
								.format();
						}
						// if (routeWay.size.value) {
						//   routeTemplate.dimension = {id: routeWay.size.value};
						// }
						if (routeWay.loadingUnloading.value.length > 0) {
							routeTemplate.loadingTypes = routeWay.loadingUnloading.value.map(
								(item) => {
									return { id: item };
								}
							);
						}
						if (routeWay.bodyType.value.length > 0) {
							routeTemplate.bodyTypes = routeWay.bodyType.value.map(
								(item) => {
									return { id: item };
								}
							);
						}
						if (routeWay.routePrice) {
							routeTemplate.routeSumm = routeWay.routePrice;
						}
						if (routeWay.stepRate) {
							routeTemplate.step = routeWay.stepRate;
						}
						if (routeWay.comment.value && routeWay.comment.value !== "") {
							routeTemplate.commentValue = routeWay.comment.value;
						}
						if (routeWay.dimension) {
							routeTemplate.dimension = routeWay.dimension;
						}
						if (routeWay.optionalDimensions) {
							routeTemplate.optionalDimensions =
								routeWay.optionalDimensions;
						}
						if (routeWay.weekTemplate) {
							const allRoutes = [
								...routeWay.weekTemplate.monday,
								...routeWay.weekTemplate.tuesday,
								...routeWay.weekTemplate.wednesday,
								...routeWay.weekTemplate.thursday,
								...routeWay.weekTemplate.friday,
								...routeWay.weekTemplate.saturday,
								...routeWay.weekTemplate.sunday,
							];
							routeTemplate.routeDateTemplates = [];
							allRoutes.forEach((item) => {
								if (item.time)
									routeTemplate.routeDateTemplates.push({
										dateTemplate:
											item.weekday +
											" this week " +
											item.time,
									});
							});
						}
						return routeTemplate;
					}
				);
			}
			return calculationFormData;
		},
		isValid() {
			return !this.$v.form.$anyError;
		},
	},
	validations: {
		form: {
			name: {
				required,
				maxLength: maxLength(150),
			},
			organizations: {
				required,
			},
		},
	},
	mounted() {
		this.contractorRemote("");
		this.routeWaysShortlist("", 0);
		this.transportVehiclesDimensionsTypes();
		this.transportVehiclesLoadingTypes();
		this.transportVehiclesTenderBodyTypes();
		this.customersContractorsListsList();
		this.getOrganizationsList();
	},
	methods: {
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
     addComment(index) {
    this.form.routeWays[index].comment.visible = true;
    },
      saveComment(index) {
      this.form.routeWays[index].comment.visible = true;
      this.showSuccess("Комментарий сохранён");
      this.updateButton = true;
      },
    updateComment(index) {
      this.form.routeWays[index].comment.visible = true;
      this.showSuccess("Комментарий обновлён");
    },
    deleteComment(index) {
      this.form.routeWays[index].comment.visible = false;
			this.form.routeWays[index].comment.value = null;
	    this.updateButton = null;
    },
	destroyComment(index) {
		this.form.routeWays[index].comment.visible = false;
			this.form.routeWays[index].comment.value = null;
	  	this.updateButton = null;
		this.showInfo("Комментарий удалён");
	},
		alternativeAmountValue(vat, value) {
			if (value) {
				if (!vat) {
					return "(" + valueToValueWithVAT(value) + " руб. С НДС)";
				} else {
					return (
						"(" + valueToValueWithoutVAT(value) + " руб. Без НДС)"
					);
				}
			} else {
				return "";
			}
		},
		getSelectedDimensions(value, index) {
			if (value.dimension) {
				this.form.routeWays[index].dimension = value.dimension;
			}
			if (value.optionalDimensions) {
				this.form.routeWays[index].optionalDimensions =
					value.optionalDimensions;
			}
		},
		clearContractorsList() {
			this.contractors.items = [];
		},
		tenderComputing(index, date) {
			if (
				this.form.routeWays[index].startDate &&
				this.form.routeWays[index].stopDate
			) {
				// logic for resetting the wrong date
				const startX = moment(
					this.form.routeWays[index].startDate
				).format("x");
				const stopX = moment(
					this.form.routeWays[index].stopDate
				).format("x");
				if (startX > stopX) {
					if (date === "start") {
						this.form.routeWays[index].stopDate = "";
					} else {
						this.form.routeWays[index].startDate = "";
					}
				}

				// logic for calculating the number of routes
				const start = moment(this.form.routeWays[index].startDate);
				const stop = moment(this.form.routeWays[index].stopDate);
				const days = stop.diff(start, "days") + 1;

				const startWeekday = moment(
					this.form.routeWays[index].startDate
				).format("e");
				const stopWeekday = moment(
					this.form.routeWays[index].stopDate
				).format("e");

				let extraDaysCount = 0;
				if (startWeekday === "0") {
					extraDaysCount = 1 + Number(stopWeekday);
				} else if (startWeekday === "1") {
					extraDaysCount = Number(stopWeekday);
				} else {
					extraDaysCount =
						8 - Number(startWeekday) + Number(stopWeekday);
				}
				const fullWeeks = Math.floor(days / 7);

				let missingDaysCount = 0;
				if (startWeekday === "0") {
					missingDaysCount = 6;
				} else {
					missingDaysCount = Number(startWeekday) - 1;
				}
				if (stopWeekday !== "0") {
					missingDaysCount =
						missingDaysCount + 7 - Number(stopWeekday);
				}
				const allWeeks = (days + missingDaysCount) / 7;
				this.data.routeWays[index].weekCount = allWeeks;

				let allRoutes = 0;
				this.data.routeWays[index].weekdaysCounters = [
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
					fullWeeks,
				];
				if (allWeeks > fullWeeks) {
					if (
						Number(stopWeekday) + 1 === Number(startWeekday) ||
						(Number(stopWeekday) === 6 &&
							Number(startWeekday) === 0)
					) {
						// nothing
					} else if (startWeekday <= stopWeekday) {
						for (let i = startWeekday; i <= stopWeekday; i++) {
							this.data.routeWays[index].weekdaysCounters[i]++;
						}
					} else {
						if (Number(stopWeekday) > 0) {
							for (let i = stopWeekday; Number(i) > 0; i--) {
								this.data.routeWays[index].weekdaysCounters[
									i
								]++;
							}
						}
						for (let i = startWeekday; Number(i) <= 6; i++) {
							this.data.routeWays[index].weekdaysCounters[i]++;
						}
						this.data.routeWays[index].weekdaysCounters[0]++;
					}
				}
				const routeWayIndex = index;
				this.data.routeWays[index].weekdaysCounters.forEach(
					(item, index) => {
						if (!item) {
							if (index === 1) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.monday = [];
							} else if (index === 2) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.tuesday = [];
							} else if (index === 3) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.wednesday = [];
							} else if (index === 4) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.thursday = [];
							} else if (index === 5) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.friday = [];
							} else if (index === 6) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.saturday = [];
							} else if (index === 0) {
								this.form.routeWays[
									routeWayIndex
								].weekTemplate.sunday = [];
							}
						}
					}
				);

				allRoutes =
					this.form.routeWays[index].weekTemplate.monday.length *
						this.data.routeWays[index].weekdaysCounters[1] +
					this.form.routeWays[index].weekTemplate.tuesday.length *
						this.data.routeWays[index].weekdaysCounters[2] +
					this.form.routeWays[index].weekTemplate.wednesday.length *
						this.data.routeWays[index].weekdaysCounters[3] +
					this.form.routeWays[index].weekTemplate.thursday.length *
						this.data.routeWays[index].weekdaysCounters[4] +
					this.form.routeWays[index].weekTemplate.friday.length *
						this.data.routeWays[index].weekdaysCounters[5] +
					this.form.routeWays[index].weekTemplate.saturday.length *
						this.data.routeWays[index].weekdaysCounters[6] +
					this.form.routeWays[index].weekTemplate.sunday.length *
						this.data.routeWays[index].weekdaysCounters[0];
				this.data.routeWays[index].routeCount = allRoutes;
			} else {
				this.data.routeWays[index].weekCount = null;
				this.data.routeWays[index].routeCount = null;
				this.data.routeWays[index].weekdaysCounters = [];
				this.form.routeWays[index].weekTemplate.monday = [];
				this.form.routeWays[index].weekTemplate.tuesday = [];
				this.form.routeWays[index].weekTemplate.wednesday = [];
				this.form.routeWays[index].weekTemplate.thursday = [];
				this.form.routeWays[index].weekTemplate.friday = [];
				this.form.routeWays[index].weekTemplate.saturday = [];
				this.form.routeWays[index].weekTemplate.sunday = [];
			}
		},
		async routeWaysShortlist(name, index) {
			if (typeof index !== "number") {
				index = this.data.focusedPlayedRouteWaySelect;
			}
			this.loading = true;
			const response = await routeWaysShortlist({
				query: name,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.routeWays[
					index
				].playedRouteWay.options = response.data.items.map((item) => {
					let itemCode = item.code;
					if (item.transportationType && item.transportationType.name) {
					  itemCode = `${item.code} (${item.transportationType.name})`
					}
					return { value: item.id, text: itemCode };
				});
			}
			this.form.routeWays[index].dimensions = {
				id: null,
				dimension: null,
				optionalDimensions: [],
			};
			this.loading = false;
		},
		async routeWayRead(index, routeWayId) {
			this.loading = true;
			if (routeWayId) {
				const response = await routeWayRead(routeWayId);
				if (response && response.status === 200) {
					this.customersRouteTariffTenderRead(index);
					const departments = [];
					response.data.routeWayPoints.forEach((point) => {
						if (point.rowNumber === 1) {
							this.data.routeWays[index].firstRouteWayTimezone =
								point.department.timeZone;
						}
						this.form.routeWays[
							index
						].routeWayCode = this.form.routeWays[
							index
						].playedRouteWay.options.find(
							(item) =>
								item.value ==
								this.form.routeWays[index].playedRouteWay.value
						).text;
						departments.push(point.department.name);
					});
					this.form.routeWays[index].department = departments;
					this.form.routeWays[index].routeNumber = index;
					if (response.data.routeWayTransportations.length > 0) {
						const idx = index;
						this.form.routeWays[
							index
						].routeTransportations.options = response.data.routeWayTransportations.map(
							(item) => {
								item.isMain &&
									(this.form.routeWays[
										idx
									].routeTransportations.value =
										item.transportation.id);
								return {
									value: item.transportation.id,
									text: item.transportation.name,
									isMain: item.isMain,
								};
							}
						);
					}
					this.data.routeWays[index].transportationType =
						response.data.transportationType;
					this.data.routeWays[
						index
					].points = response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					);
					this.data.routeWays[
						index
					].fullDistance = response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2);
					this.data.routeWays[
						index
					].timeSummer = response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0)
						.toFixed(2);
					this.form.routeWays[index].dimensions = {
						id: routeWayId,
						dimension: null,
						optionalDimensions: [],
					};
				}
			}
			this.loading = false;
		},
		async customersRouteTariffTenderRead(index) {
			this.loading = true;
			if (
				this.form.routeWays[index].playedRouteWay.value &&
				this.form.routeWays[index].autoTariff
			) {
				const response = await customersRouteTariffTenderRead(
					this.form.routeWays[index].playedRouteWay.value
				);
				if (response && response.status === 200) {
					this.form.routeWays[index].routePrice = response.data;
				}
			} else {
				this.form.routeWays[index].routePrice = null;
			}
			this.loading = false;
		},
		switchRouteWayWeek(index, weekday) {
			if (this.form.routeWays[index].weekTemplate[weekday].length > 0) {
				this.form.routeWays[index].weekTemplate[weekday] = [];
			} else {
				this.createRouteWayWeekItem(index, weekday);
			}
			this.tenderComputing(index);
		},
		createRouteWayWeekItem(index, weekday) {
			this.form.routeWays[index].weekTemplate[weekday].push({
				weekday: weekday,
				time: "",
			});
			this.tenderComputing(index);
		},
		removeRouteWayWeekItem(index, weekday, weekItemIndex) {
			this.form.routeWays[index].weekTemplate[weekday].splice(
				weekItemIndex,
				1
			);
			this.tenderComputing(index);
		},
		async transportVehiclesDimensionsTypes() {
			this.loading = true;
			const response = await transportVehiclesDimensionsTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.size.options = response.data.items.map((item) => {
					if (item.name === "20 т(80-93 м3)") {
						this.data.defaultDimension = item.id;
					}
					return { value: item.id, text: item.name };
				});
			}
			this.form.routeWays[0].size.value = this.data.defaultDimension;
			this.loading = false;
		},
		async transportVehiclesLoadingTypes() {
			this.loading = true;
			const response = await transportVehiclesLoadingTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.loadingUnloading.options = response.data.items.map(
					(item) => {
						this.data.defaultLoadingUnloading.push(item.id);
						return { value: item.id, text: item.name };
					}
				);
			}
			this.form.routeWays[0].loadingUnloading.value = this.data.defaultLoadingUnloading;
			this.loading = false;
		},
		async transportVehiclesTenderBodyTypes() {
			this.loading = true;
			const response = await transportVehiclesAuctionBodyTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.bodyType.options = response.data.items.map((item) => {
					if (item.name === "Тент с усиленной обрешеткой") {
						this.data.defaultBodyType.push(item.id);
					}
					return { value: item.id, text: item.name };
				});
			}
			this.form.routeWays[0].bodyType.value = this.data.defaultBodyType;
			this.loading = false;
		},
		async getOrganizationsList(name) {
			this.loading = true;
			const params = { name: name, limit: 20 };
			const response = await organizationsList(params);
			if (response && response.status === 200) {
				this.form.organizations.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async customersContractorsListsList() {
			this.loading = true;
			const response = await customersContractorsListsList(
				this.$store.state.user.ownerId,
				{ limit: 9999 }
			);
			if (response && response.status === 200) {
				this.form.listOfContractors.options = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async onContractorsListChoose(listId) {
			this.loading = true;
			const response = await customersContractorsListRead(
				this.$store.state.user.ownerId,
				listId
			);
			if (response && response.status === 200) {
				response.data.contractors.forEach((contractor) => {
					if (
						contractor.customers &&
						contractor.customers.length > 0 &&
						contractor.customers[0].rating
					) {
						contractor.rating = contractor.customers[0].rating;
					}
					this.contractors.items = this.contractors.items.filter(
						(x) => x.id !== contractor.id
					);
					this.contractors.items.unshift(contractor);
				});
				this.form.listOfContractors.value = null;
			}
			this.loading = false;
		},
		async contractorRemote(query) {
			this.loading = true;
			const response = await customersContractorsList(
				this.$store.state.user.ownerId,
				{
					name: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.contractors.options = response.data.items.map(
					(item) => {
						return {
							value: item.contractor.id,
							text: item.contractor.name,
						};
					}
				);
			}
			this.updateContractorOptions();
			this.loading = false;
		},
		updateContractorOptions() {
			this.form.contractors.options = this.form.contractors.options.map(
				(item) => {
					return {
						...item,
						...{ disabled: !!this.inContractorsList(item.value) },
					};
				}
			);
		},
		inContractorsList(id) {
			return this.contractors.items.find((contractor) => {
				return contractor.id === id;
			});
		},
		async onContractorChoose(contractorId) {
			this.loading = true;
			const response = await customersContractorRead(
				this.$store.state.user.ownerId,
				contractorId
			);
			if (response && response.status === 200) {
				response.data.contractor.rating = response.data.rating;
				this.contractors.items = this.contractors.items.filter(
					(x) => x.id !== response.data.contractor.id
				);
				this.contractors.items.unshift(response.data.contractor);
				this.form.contractors.value = null;
			}
			this.updateContractorOptions();
			this.loading = false;
		},
		deleteChosenContractor(contractorId) {
			this.contractors.items = this.contractors.items.filter(
				(item) => item.id !== contractorId
			);
		},
		addRouteWay() {
			this.form.routeWays.push({
				playedRouteWay: {
					options: [],
					value: "",
				},
				comment: {
					visible: false,
					value: null,
				},
				routeTransportations: {
					value: "",
					options: [],
				},
				routeNumber: null,
				routeWayCode: null,
				routePrice: null,
				routeType: "oneWay",
				stepRate: 500,
				autoTariff: true,
				startDate: null,
				stopDate: null,
				department: [],
				weekTemplate: {
					monday: [],
					tuesday: [],
					wednesday: [],
					thursday: [],
					friday: [],
					saturday: [],
					sunday: [],
				},
				size: {
					value: null,
				},
				loadingUnloading: {
					value: [],
				},
				bodyType: {
					value: [],
				},
			});
			const newRouteWayIndex = this.form.routeWays.length - 1;
			this.routeWaysShortlist("", newRouteWayIndex);
			this.form.routeWays[
				newRouteWayIndex
			].size.value = this.data.defaultDimension;
			this.form.routeWays[
				newRouteWayIndex
			].loadingUnloading.value = this.data.defaultLoadingUnloading;
			this.form.routeWays[
				newRouteWayIndex
			].bodyType.value = this.data.defaultBodyType;
			this.data.routeWays.push({
				firstRouteWayTimezone: null,
				fullDistance: null,
				timeSummer: null,
				points: [],
				weekCount: null,
				routeCount: null,
				weekdaysCounters: [],
			});
		},
		deleteRouteWay(index) {
			this.form.routeWays.splice(index, 1);
			this.data.routeWays.splice(index, 1);
		},
		async onSubmit() {
			if (this.validate()) {
				this.loading = true;
				const response = await customersTenderCreate(
					this.$store.state.user.ownerId,
					this.formStrAdd
				);
				if (response && response.status === 201) {
					this.showSuccess("Тендер создан");
					this.$router.push({ path: "/tenders/customer" });
				}
				this.loading = false;
			}
		},
		async onStart() {
			if (this.validate()) {
				this.loading = true;
				const response = await customersTenderCreateAndRun(
					this.$store.state.user.ownerId,
					this.formStrStart
				);
				if (response && response.status === 201) {
					this.showSuccess("Тендер создан и подготовлен к запуску");
					this.$router.push({
						path:
							"/customers/id" +
							this.$store.state.user.ownerId +
							"/tender/id" +
							response.data.id,
					});
				}
				this.loading = false;
			}
		},
		chkState(val) {
			const field = this.$v.form[val];
			if (!field.$dirty) {
				return null;
			} else return !field.$invalid;
		},
		findFirstError(component = this) {
			if (component.state === false) {
				if (component.$refs.input) {
					component.$refs.input.focus();
					return true;
				}
				if (component.$refs.check) {
					component.$refs.check.focus();
					return true;
				}
			}
			let focused = false;
			component.$children.some((child) => {
				focused = this.findFirstError(child);
				return focused;
			});

			return focused;
		},
		validate() {
			this.$v.$touch();
			this.$nextTick(() => this.findFirstError());
			return this.isValid;
		},
	},
};
</script>

<style lang="scss">
.custom-fieldset {
	&:nth-last-child(2) {
		.route-and-flight-details__add-route-way {
			display: initial;
		}
	}

	&__title {
		font-size: 36px;
		line-height: 44px;
		font-weight: normal;
	}
}

.VueTables__table {
	margin-bottom: 2rem;
}

.route-and-flight-details {
	position: relative;
	margin-top: 24px;

	&:before {
		position: absolute;
		top: 0;
		left: 66.66667%;
		transform: translateX(-50%);
		display: block;
		width: 1px;
		height: 100%;
		background-color: #c8ced3;
		content: "";

		@media screen and (max-width: 1199px) {
			display: none;
		}
	}

	&__dates {
		@media screen and (max-width: 1199px) {
			position: relative;
			margin-top: 40px;
			padding-top: 20px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				border: 1px solid #c8ced3;
				content: "";
			}
		}
	}
	&__title {
		margin-top: 0;
		margin-bottom: 8px;
	}
	&__route-container {
		position: relative;
		padding-bottom: 37px;

		&:last-child {
			padding-bottom: 0;
		}
	}
	&__date {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		&-title {
			flex: 0 0 100%;
			max-width: 100%;
		}
		&-number {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 15px;
		}
		&-time {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 15px;
		}
	}
	&__transport {
		padding-bottom: 24px;
	}
	&__form-group {
		&:last-child {
			margin-bottom: 0;
		}

		.col-form-label {
			font-weight: bold;
		}
	}
	&__buttons {
		display: block;
		width: 100%;
	}
	&__add-route-way {
		display: none;
	}
	&__delete-route-way {
		margin-left: 0.5rem;

		@media screen and (max-width: 575px) {
			display: block;
			margin-top: 0.625rem;
			margin-left: auto;
		}
	}
}

.route-tender {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 3px;
		height: 100%;
		background-color: #c8ced3;
		content: "";
	}

	&__item {
		position: relative;
		z-index: 1;
		max-width: 480px;
		padding: 10px 6px;
		margin-top: 18px;
		background: #f3f4f5;
		border: 1px solid #c8ced3;

		&:first-child {
			margin-top: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-top: 10px solid #c8ced3;
			content: "";
		}

		&-header {
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
		}
		&-number {
			padding-right: 20px;
			white-space: nowrap;
		}
		&-type {
			width: auto;
			min-width: 136px;
			padding: 0 5px;
		}
		&-distance {
			padding-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 0;
			}
		}
		&-address {
			margin-top: 12px;
			margin-bottom: 0;
		}
		&-footer {
			padding-top: 28px;
			display: flex;
			justify-content: space-between;
		}
	}
}

.route-way-week {
	margin-bottom: 16px;

	&__head {
		display: flex;

		&-item {
			display: block;
			flex: 0 0 14.2857%;
			max-width: 14.2857%;
			text-align: center;
			border: 1px solid gray;
			transition: all 0.33s;
			cursor: pointer;

			&:disabled {
				background-color: #e4e7ea;
				cursor: not-allowed;
			}

			&.active {
				background-color: #20a8d8;
				border-color: #20a8d8;
				color: white;
			}
		}
	}
	&__body {
		display: flex;

		&-weekday {
			display: block;
			flex: 0 0 14.2857%;
			max-width: 14.2857%;
			padding-left: 2px;
			padding-right: 2px;
		}
		&-item {
			position: relative;
			display: block;
			padding-top: 24px;
			margin-bottom: 0;

			&:before {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				width: 3px;
				height: 24px;
				background-color: rgba(#20a8d8, 0.25);
				content: "";
			}
		}
		&-time {
			height: 20px;
			padding: 2px;
			font-size: 16px;
			line-height: 1;
			text-align: center;
			-moz-appearance: textfield;
			appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				display: none;

				&:hover {
					display: none;
				}
			}

			::-ms-clear,
			::-ms-reveal {
				display: none;
				width: 0;
				height: 0;
			}

			&:focus,
			&:hover {
				-moz-appearance: number-input;
				appearance: number-input;
			}
		}
		&-remove {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			padding: 0;
			background-color: transparent;
			border: 0;
			font: normal normal normal 20px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: red;
			cursor: pointer;
			transition: color 0.33s;

			&:before {
				content: "\f00d";
			}
		}
		&-add {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 16px;
			height: 16px;
			margin: 24px auto 0;
			padding: 0;
			background-color: transparent;
			border: 0;
			font: normal normal normal 16px/1 FontAwesome;
			text-align: center;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: #20a8d8;
			cursor: pointer;

			&:before {
				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				width: 3px;
				height: 24px;
				background-color: rgba(#20a8d8, 0.25);
				content: "";
			}
			&:after {
				margin-top: -2px;
				margin-left: -1.5px;
				content: "\f0fe";
			}

			&:first-child {
				display: none;
			}
		}
	}
}

.terms-and-participants {
	&__contractors {
		padding: 1rem;
	}
	&__start-price {
		position: relative;
		height: 100%;
		padding: 1rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.5px;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
			@media screen and (max-width: 991px) {
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}
	&__tender-duration {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
}

.tab-content {
	.tab-pane.terms-and-participants {
		padding: 0;
	}
}

/* Update tenders styles */
.tender-page {
	&__title {
		font-size: 40px;
		line-height: 1;
		margin-bottom: 40px;
	}

	.form-group {
		> label,
		> legend {
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
		}
	}
}

.custom-fieldset {
	margin-top: 60px;

	&__title {
		margin-bottom: 16px;
		font-size: 32px;
		line-height: 38px;
		font-weight: normal;
	}
	&__subtitle {
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
	}
	&__desc {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 24px;
		line-height: 28px;
		font-weight: normal;
	}
}

.route-and-flight-details {
	&:before {
		display: none;
	}
	&__title {
		margin-bottom: 16px;
		font-size: 28px;
		line-height: 34px;
		font-weight: normal;
	}
	&__route-container {
		padding-bottom: 40px;
	}
}
/* Update tenders styles */
</style>
